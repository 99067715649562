import { memo } from 'react'
import type { FC } from 'react'
import type { NextPage } from 'next'
import { useTranslator } from '@/hooks'
import { useRouter } from 'next/router'
import { getTranslations } from '@/context'
import { Layout } from '../components'
import { HeadTag, Button, HeaderMarquee } from '@dy/commons/components'
import { initializeApollo, GET_LAYOUT } from '@/api'
import { CATEGORIES, BANNER } from '@dy/commons/utils'
import { ErrorMain, ErrorSection } from './_error'

export interface ErrorProps {
  error?: any,
}

export async function getStaticProps({ locale }) {
  const dictionary = getTranslations(locale, ['section_subscribe'])
  const apolloClient = initializeApollo()

  const { data:resLayout } = await apolloClient.query({
    query: GET_LAYOUT,
    variables: {
      bannerType: BANNER.SLIM,
      type: CATEGORIES.PRODUCT
    }
  })

  let modals
  if(resLayout?.modals) modals = resLayout?.modals?.edges?.map(modal => modal.node)

  if(!resLayout) return { notFound: true }

  return {
    props: {
      layout: {...resLayout, modals},
      translations: dictionary,
      initialApolloState: apolloClient.cache.extract()
    }
  }
}

const NotFoundPage: NextPage<ErrorProps> & { Layout?:FC } = memo(() => {
  const { push } = useRouter()
  const { t } = useTranslator()

  const onClick = () => {
    push('/')
  }

  return (
    <>
      <HeadTag seo={{
        title: `Mammafiore | ${t('commons.page_error.title')}`,
        description: t('commons.page_error.description') as string
      }} />
      <HeaderMarquee data={t('commons.page_not_found.404')} />
      <ErrorMain>
        <ErrorSection>
          <h2>{t('commons.page_not_found.title')}</h2>
          <p>{t('commons.page_not_found.description')}</p>
          <Button onClick={onClick}>{t('commons.page_not_found.goback_homepage')}</Button>
        </ErrorSection>
      </ErrorMain>
    </>
  )
})

NotFoundPage.Layout = Layout
export default NotFoundPage
