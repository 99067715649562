import { memo, useEffect } from 'react'
import type { FC } from 'react'
import type { NextPage } from 'next'
import { useCustomer, useTranslator } from '@/hooks'
import { useRouter } from 'next/router'
import { getTranslations } from '@/context'
import styled from 'styled-components'
import { Layout } from '../components'
import { HeadTag, Button, HeaderMarquee } from '@dy/commons/components'
import { vw, mq, getP26_1, getP18_1 } from '@dy/commons/styles'
import { initializeApollo, GET_LAYOUT } from '@/api'
import { CATEGORIES, BANNER, cookie } from '@dy/commons/utils'

export interface ErrorProps {
  errorCode?: any,
  statusCode?: any,
}

export async function getStaticProps({ locale }) {
  const dictionary = getTranslations(locale, ['section_subscribe'])
  const apolloClient = initializeApollo()

  const { data:resLayout } = await apolloClient.query({
    query: GET_LAYOUT,
    variables: {
      bannerType: BANNER.SLIM,
      type: CATEGORIES.PRODUCT
    }
  })

  let modals
  if(resLayout?.modals) modals = resLayout?.modals?.edges?.map(modal => modal.node)
  if(!resLayout) return { notFound: true }

  return {
    props: {
      layout: {...resLayout, modals},
      translations: dictionary,
      initialApolloState: apolloClient.cache.extract()
    }
  }
}

const ErrorPage: NextPage<ErrorProps> & { Layout?: FC } = memo(({ errorCode, statusCode }) => {
  console.log('❌ errorCode:', errorCode)
  console.log('❌ statusCode:', statusCode)
  const { push } = useRouter()
  const { t } = useTranslator()
  const { setCustomer } = useCustomer()

  const onClick = () => {
    push('/')
  }

  useEffect(() => {
    cookie.removeCookie('SHOP_TOKEN')
    cookie.removeCookie('SHOP_SESSION_TOKEN')
    cookie.removeCookie('BC_CUSTOMER_ID')
    cookie.removeCookie('DY_CUSTOMER_TOKEN')
    setCustomer(null)
  }, [setCustomer])

  return (
    <>
      <HeadTag seo={{
        title: `Mammafiore | ${t('commons.page_error.title')}`,
        description: t('commons.page_error.description') as string
      }} />
      <HeaderMarquee data={t('commons.page_error.500')} />
      <ErrorMain>
        <ErrorSection>
          <h2>{t('commons.page_error.title')}</h2>
          <p>{t('commons.page_error.description')}</p>
          <Button onClick={onClick}>{t('commons.page_error.goback_homepage')}</Button>
        </ErrorSection>
      </ErrorMain>
    </>
  )
})

ErrorPage.Layout = Layout
export default ErrorPage


export const ErrorMain = styled.main`
  flex-grow: 2;
  overflow-x: hidden;
  padding: 0;
  position: relative;

  ${mq.greaterThan('tablet')} {
    overflow-x: inherit;
  }

  ${mq.greaterThan('desktop-wide')} {
    margin: 0 auto;
    max-width: 1650px;
  }
`

export const ErrorSection = styled.section`
  grid-column: 1 / span 6;
  padding: ${vw(50, 'mobile')} ${vw(20, 'mobile')};
  align-items: center;
  margin-bottom:${vw(50, 'mobile')} ;

  ${mq.greaterThan('tablet')} {
    padding: ${vw(50, 'nexus7')} ${vw(20, 'nexus7')};
    margin-bottom:${vw(50, 'nexus7')};
  }

  ${mq.greaterThan('tablet')} {
    grid-column: 5 / span 4;
    padding: ${vw(60, 'desktop')} 0;
    margin-bottom:${vw(60, 'desktop')} ;
  }

  ${mq.greaterThan('desktop')} {
    padding: 60px 0;
    margin-bottom: 60px;
  }

  button {
    color: ${({ theme }) => theme.colors.white};
    margin-top: ${vw(60, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      margin-top:${vw(60, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      margin-top:${vw(30, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      margin-top: 30px;
    }
  }

  h2, p {
    font-family: ${({ theme }) => theme.fonts.secondaryFont};
    padding: 15px;
    text-align: center;
  }

  h2 {
    ${getP26_1()}
    color: ${({ theme }) => theme.colors.red};
    font-weight: 700 !important;
  }

  p {
    ${getP18_1()}
    color: ${({ theme }) => theme.colors.darkred};
  }
`
